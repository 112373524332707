@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 60 10% 98%;
    --foreground: 20 10% 15%;
    --card: 0 0% 100%;
    --card-foreground: 20 10% 15%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 10% 15%;
    --primary: 40 30% 40%;
    --primary-foreground: 60 10% 98%;
    --secondary: 60 15% 90%;
    --secondary-foreground: 20 10% 15%;
    --muted: 60 10% 90%;
    --muted-foreground: 20 10% 40%;
    --accent: 40 20% 80%;
    --accent-foreground: 20 10% 15%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 10% 98%;
    --border: 40 15% 85%;
    --input: 40 15% 85%;
    --ring: 40 30% 40%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 20 10% 10%;
    --foreground: 60 10% 90%;
    --card: 20 10% 15%;
    --card-foreground: 60 10% 90%;
    --popover: 20 10% 15%;
    --popover-foreground: 60 10% 90%;
    --primary: 40 30% 60%;
    --primary-foreground: 20 10% 10%;
    --secondary: 20 10% 20%;
    --secondary-foreground: 60 10% 90%;
    --muted: 20 10% 20%;
    --muted-foreground: 60 10% 70%;
    --accent: 40 20% 30%;
    --accent-foreground: 60 10% 90%;
    /* --destructive: #E57373; */
    --destructive: 0 45% 45%;
    --destructive-foreground: 0 45% 45%;
    /* --destructive-foreground: 60 10% 98%; */
    --border: 20 10% 30%;
    --input: 20 10% 30%;
    --ring: 40 30% 60%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.card {
  @apply bg-card text-card-foreground rounded-lg shadow-md;
}

.input-search {
  @apply border border-input rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-primary/50;
}

.table-header {
  @apply bg-secondary text-secondary-foreground;
}

.table-row {
  @apply border-b border-border hover:bg-muted/50 transition-colors;
}